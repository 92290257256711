<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Catálogos</h4>
        </div>

        <div class="d-flex justify-content-end">
          <search-input
            v-model="searchCatalog"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
          <b-button
            class="ml-2 pb-2 mb-2"
            variant="primary"
            @click="openCreateCatalogModal"
            >CRIAR CATÁLOGO</b-button
          >
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="allCatalogs"
            :fields="catalogFields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                title="Editar catálogo"
                class="mr-1"
                @click="openEditCatalogModal(row.item)"
              >
                <b-icon icon="pencil" />
              </b-button>

              <b-button
                size="sm"
                title="Desativar catálogo"
                @click="openDeactivateModal(row.item)"
              >
                <b-icon icon="trash" />
              </b-button>
            </template>
          </b-table>

          <b-button v-if="showButton" @click="getMoreCatalog">
            CARREGAR MAIS
            <b-icon
              v-if="isLoading"
              icon="arrow-clockwise"
              animation="spin"
            ></b-icon>
          </b-button>
        </b-card-text>

        <b-modal
          v-model="showEditCatalogModal"
          title="Editar catálogo"
          hide-footer
        >
          <b-form @submit.prevent="editDescription">
            <form-input
              v-model="catalogDescription"
              icon="pencil"
              label="Descrição"
              :errors="errors['description']"
              required
            />

            <form-input
              v-model="catalogCode"
              icon="card-heading"
              label="Código"
              :errors="errors['description']"
              required
            />

            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit"> SALVAR </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal v-model="showDeactivateModal" title="Desativar" hide-footer>
          <p>Deseja desativar o catálogo "{{ this.catalogDescription }}"?</p>
          <div class="d-flex justify-content-end">
            <b-button @click="deactivateCatalog"> DESATIVAR </b-button>
          </div>
        </b-modal>

        <b-modal
          v-model="showCreateCatalogModal"
          title="Adicione as informações do catálogo"
          size="lg"
          hide-footer
        >
          <b-form @submit.prevent="createCatalog">
            <form-input
              v-model="catalogInfo.description"
              label="Descrição"
              icon="pencil"
              :errors="errors['description']"
              required
            />

            <form-input
              v-model="catalogInfo.code"
              icon="card-heading"
              label="Código"
              :errors="errors['code']"
              required
            />

            <b-button variant="primary" type="submit">CRIAR</b-button>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import SearchInput from "../../components/shared/SearchInput.vue";

export default {
  components: { FormInput, SearchInput },
  data() {
    return {
      page: 2,
      isLoading: false,
      showButton: true,
      showEditCatalogModal: false,
      showDeactivateModal: false,
      showCreateCatalogModal: false,
      searchCatalog: "",
      isLoadingSearch: false,
      catalogDescription: "",
      catalogCode: "",
      catalogId: null,
      catalogInfo: {
        description: "",
        code: "",
      },
      errors: {},
      allCatalogs: [],
      catalogFields: [
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "code",
          label: "Código",
          thStyle: { width: "150px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    openEditCatalogModal(item) {
      this.errors = {};
      this.catalogId = item.id;
      this.catalogDescription = item.description;
      this.catalogCode = item.code;
      this.showEditCatalogModal = true;
    },

    openDeactivateModal(item) {
      this.errors = {};
      this.catalogId = item.id;
      this.catalogDescription = item.description;
      this.showDeactivateModal = true;
    },

    openCreateCatalogModal() {
      this.errors = {};
      this.showCreateCatalogModal = true;
    },

    async createCatalog() {
      this.errors = {};
      try {
        const { data } = await http.post("/catalog/create", this.catalogInfo);
        this.allCatalogs.push(data);
        this.showCreateCatalogModal = false;
        this.setToastedSuccess({
          message: "Catálogo criado com sucesso",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async editDescription() {
      this.errors = {};
      try {
        const { data } = await http.put(`catalog/${this.catalogId}`, {
          description: this.catalogDescription,
          code: this.catalogCode,
        });
        const index = this.allCatalogs.findIndex((e) => e.id === data.id);
        this.allCatalogs.splice(index, 1, data);
        this.showEditCatalogModal = false;
        this.setToastedSuccess({
          message: "Catálogo modificado com sucesso",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async deactivateCatalog() {
      this.showDeactivateModal = false;
      this.setOverlay(true);
      await http.put(`/catalog/deactivate/${this.catalogId}`);

      const catalogs = this.allCatalogs.filter(
        (address) => address.id !== this.catalogId
      );

      this.allCatalogs = catalogs;
      this.setOverlay(false);
      this.setToastedSuccess({
        message: "Catálogo desativado com sucesso",
      });
    },

    async getMoreCatalog() {
      if (this.searchCatalog) {
        this.isLoadingSearch = true;

        const { data } = await http(`/catalog`, {
          params: {
            search: this.searchCatalog,
            page: this.page,
          },
        });

        this.allCatalogs = [...this.allCatalogs, ...data];
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`catalog?page=${this.page}`);
        this.allCatalogs.push(...data);
        if (data.length < 20) {
          this.showButton = false;
        }
        this.isLoading = false;
      }
      this.page += 1;
    },

    async search() {
      this.page = 2;
      if (this.searchCatalog) {
        this.isLoadingSearch = true;

        const { data } = await http(`/catalog`, {
          params: {
            search: this.searchCatalog,
          },
        });

        this.allCatalogs = data;
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`catalog`);
        this.allCatalogs = data;
        this.isLoading = false;
        this.showButton = data.length === 20;
      }
    },
  },
  async created() {
    this.setOverlay(true);
    const { data } = await http("catalog?page=1");
    this.allCatalogs = data;
    this.showButton = data.length === 20;
    this.setOverlay(false);
  },
};
</script>
